import React, {
  ForwardRefRenderFunction,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';

interface ImageFaderProps extends HTMLAttributes<HTMLDivElement> {
  backgroundImages: string[];
  rotationSpeed?: number;
}

interface ImageProps {
  backgroundImageUrl: string;
  isActive: boolean;
}

const Image = styled.div<ImageProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transition: opacity 0.35s ease-in;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  background-image: ${({ backgroundImageUrl }) =>
    `url(//${backgroundImageUrl}?output-format=jpg&output-quality=70&resize=1600:*)`};
  background-size: cover;
  background-position: 50% 30%;
  @media ${DEVICE.tablet} {
    background-image: ${({ backgroundImageUrl }) =>
      `url(//${backgroundImageUrl}?output-format=jpg&output-quality=70&resize=1000:*)`};
  }
  @media ${DEVICE.onlyUhd} {
    background-image: ${({ backgroundImageUrl }) =>
      `url(//${backgroundImageUrl}?output-format=jpg&output-quality=70&resize=2000:*)`};
  }
`;

const DEFAULT_ROTATION_SPEED = 6000;

const ImageFader: ForwardRefRenderFunction<HTMLDivElement, ImageFaderProps> = (
  { backgroundImages, rotationSpeed = DEFAULT_ROTATION_SPEED, ...props },
  ref
) => {
  const [counter, setCounter] = useState(0);
  const activeIndex = backgroundImages.length
    ? counter % backgroundImages.length
    : 0;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCounter(counter + 1);
    }, rotationSpeed);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter, rotationSpeed]);

  return (
    <div ref={ref}>
      {backgroundImages.slice(0, counter + 2).map((imageUrl, i) => (
        <Image
          key={`banner-image-${i}`}
          backgroundImageUrl={imageUrl}
          isActive={i === activeIndex}
          {...props}
        />
      ))}
    </div>
  );
};

export default React.forwardRef<HTMLDivElement, ImageFaderProps>(ImageFader);
