import { useRouter } from 'next/router';
import {
  cosmo_leagueQuery,
  cosmo_leagueQueryVariables,
} from '../../__generated__/globalTypes';
import { GET_LEAGUE } from '../../domain/League/gql';
import { UrlQueryParams } from '../constants';
import useClientQuery from './useClientQuery';

/**
 * Gets league information
 */
const useLeagueQuery = (): {
  leagueName?: string;
  leagueCode: string;
  tagCode?: string;
  leagueLoading: boolean;
} => {
  const router = useRouter();
  const query =
    router.query.leagueCode || router.query[UrlQueryParams.LEAGUE_CODE];
  const leagueCode = Array.isArray(query) ? query[0] : query || '';

  const { data, loading } = useClientQuery<
    cosmo_leagueQuery,
    cosmo_leagueQueryVariables
  >(GET_LEAGUE, {
    variables: {
      leagueCode,
    },
    skip: !leagueCode,
  });

  const leagueData = data?.leagueDetail?.league;

  return {
    tagCode: leagueData?.tagCode,
    leagueCode,
    leagueName: leagueData?.name,
    leagueLoading: loading,
  };
};

export default useLeagueQuery;
