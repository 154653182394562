import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ImageFader from '../../../shared/components/Common/ImageFader';
import {
  BLOCK_PADDING,
  BLOCK_PADDING_MOBILE,
} from '../../../shared/components/Slider';
import useSmallBanner from '../../../shared/components/SmallBanner/useSmallBanner';
import { DEVICE } from '../../../shared/styles';
import { MAIN_PAGE_PADDING_TOP } from '../../../shared/styles/constants';
import { COLORS } from '../../../shared/styles/theme';
import { LabelCategoryList, VideoCategoryList } from './CategoryList';

const ImageContainer = styled.div`
  position: absolute;
  height: 420px;
  width: 100%;
  z-index: -1;

  @media ${DEVICE.mobileWide} {
    height: 248px;
  }
`;

const StyledImageFader = styled(ImageFader)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const BannerOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
      180deg,
      ${COLORS.jet_black_overlay_40} 0%,
      ${COLORS.jet_black} 100%
    ),
    linear-gradient(
      270deg,
      ${COLORS.jet_black_overlay_0} 0%,
      ${COLORS.jet_black_overlay_0} 48.64%,
      ${COLORS.jet_black} 100%
    );

  @media ${DEVICE.mobileWide} {
    background: linear-gradient(
      0deg,
      ${COLORS.jet_black} 0%,
      ${COLORS.jet_black_overlay_25} 100%
    );
  }
`;

const ContentContainer = styled.div`
  position: relative;
  margin-top: ${MAIN_PAGE_PADDING_TOP.desktop}px;

  @media ${DEVICE.mobileWide} {
    margin-top: 136px;
  }
`;

const LabelLogo = styled.img`
  height: 80px;
  width: auto;
  @media ${DEVICE.mobileWide} {
    height: 56px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  margin: 0 ${BLOCK_PADDING}px;

  h1 {
    font-size: 38px;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  @media ${DEVICE.mobileWide} {
    margin: 0 ${BLOCK_PADDING_MOBILE}px;
    justify-content: flex-end;

    h1 {
      font-size: 28px;
    }
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 1.4;
  margin-top: 16px;
  @media ${DEVICE.mobileWide} {
    margin-top: 14px;
    font-size: 14px;
  }
`;

interface GenreBannerProps {
  backgroundImages: string[];
  title: string;
  labelMeta?: {
    logo: string;
    catchSentence: string;
  };
  searchGenreId?: string;
}

// Number of titles images to show from the first row
const IMAGE_LIMIT = 5;

const GenreBanner: React.FC<GenreBannerProps> = ({
  backgroundImages,
  title,
  labelMeta,
  searchGenreId,
}) => {
  const trimmedImages = backgroundImages.slice(0, IMAGE_LIMIT);
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const imageFaderRef = useRef<HTMLDivElement>(null);
  useSmallBanner({
    triggerHeight: 250,
    title,
  });

  useEffect(() => {
    const onScroll = () => {
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      if (titleContainerRef.current) {
        titleContainerRef.current.style.bottom = `${scrollTop * 1.1}px`;
      }
      if (imageFaderRef.current) {
        imageFaderRef.current.style.opacity = `${1 - scrollTop / 500}`;
      }
    };

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [titleContainerRef, imageFaderRef]);

  return (
    <>
      <ImageContainer>
        <StyledImageFader
          ref={imageFaderRef}
          backgroundImages={trimmedImages}
        />
        <BannerOverlay />
      </ImageContainer>
      <ContentContainer>
        <TitleContainer ref={titleContainerRef} data-ucn="genreBanner-title">
          {labelMeta ? (
            <>
              <LabelLogo alt={title} src={`//${labelMeta.logo}`} />
              {labelMeta.catchSentence && (
                <Description>{labelMeta.catchSentence}</Description>
              )}
            </>
          ) : (
            <h1 data-ucn="genreBanner-title-text">{title}</h1>
          )}
        </TitleContainer>
        {searchGenreId &&
          (labelMeta ? (
            <LabelCategoryList genreCode={searchGenreId} />
          ) : (
            <VideoCategoryList genreCode={searchGenreId} />
          ))}
      </ContentContainer>
    </>
  );
};

export default React.memo(GenreBanner);
