import Link from 'next/link';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import type { LiveBlockFragment } from '../../../__generated__/globalTypes';
import SPOTVLogo from '../../../assets/icons/logo/spotv.svg';
import { isPoint } from '../../../shared/components/PointPriceBadge';
import Slider, {
  BLOCK_PADDING,
  BLOCK_PADDING_MOBILE,
  COUNT_BLOCK_CLASS_NAME,
} from '../../../shared/components/Slider';
import { StatelessTabs } from '../../../shared/components/Tabs';
import LiveTitleCard from '../../../shared/components/TitleCard/Live';
import ReadMoreCard from '../../../shared/components/TitleCard/ReadMore';
import { FocusStyledTitleLink } from '../../../shared/components/TitleLink';
import { UrlQueryParams } from '../../../shared/constants';
import { globalMessages } from '../../../shared/constants/messages';
import useGenreQuery from '../../../shared/hooks/useGenreQuery';
import useLabelQuery from '../../../shared/hooks/useLabelQuery';
import useLeagueQuery from '../../../shared/hooks/useLeagueQuery';
import { DEVICE } from '../../../shared/styles';
import type { HomeLiveBlockGroupTabLog } from '../../Log/__types__/home-liveBlock-groupTab';
import type { HomeLiveBlockMoreLog } from '../../Log/__types__/home-liveBlock-more';
import type { HomeLiveBlockMoreCardLog } from '../../Log/__types__/home-liveBlock-moreCard';
import type { HomeLiveBlockPageNextLog } from '../../Log/__types__/home-liveBlock-pageNext';
import type { HomeLiveBlockPagePrevLog } from '../../Log/__types__/home-liveBlock-pagePrev';
import type { HomeLiveBlockTitleCardLog } from '../../Log/__types__/home-liveBlock-titleCard';
import type { VideoGenreTopLiveBlockGroupTabLog } from '../../Log/__types__/videoGenreTop-liveBlock-groupTab';
import type { VideoGenreTopLiveBlockMoreLog } from '../../Log/__types__/videoGenreTop-liveBlock-more';
import type { VideoGenreTopLiveBlockMoreCardLog } from '../../Log/__types__/videoGenreTop-liveBlock-moreCard';
import type { VideoGenreTopLiveBlockPageNextLog } from '../../Log/__types__/videoGenreTop-liveBlock-pageNext';
import type { VideoGenreTopLiveBlockPagePrevLog } from '../../Log/__types__/videoGenreTop-liveBlock-pagePrev';
import type { VideoGenreTopLiveBlockTitleCardLog } from '../../Log/__types__/videoGenreTop-liveBlock-titleCard';
import type { VideoSubjectTopLiveBlockMoreLog } from '../../Log/__types__/videoSubjectTop-liveBlock-more';
import type { VideoSubjectTopLiveBlockMoreCardLog } from '../../Log/__types__/videoSubjectTop-liveBlock-moreCard';
import type { VideoSubjectTopLiveBlockPageNextLog } from '../../Log/__types__/videoSubjectTop-liveBlock-pageNext';
import type { VideoSubjectTopLiveBlockPagePrevLog } from '../../Log/__types__/videoSubjectTop-liveBlock-pagePrev';
import type { VideoSubjectTopLiveBlockTitleCardLog } from '../../Log/__types__/videoSubjectTop-liveBlock-titleCard';
import { getKafkaClient } from '../../Log/kafkaClient';
import BlockTitle from '../BlockTitle';
import { LIVES_PER_BLOCK } from '../constants';
import { BlockContainer } from '../RecommendedBlocks';
import {
  getKafkaLogRowIndex,
  getLiveOnAirStatus,
  hasCatchup,
  isActive,
  selectNotice,
} from '../utils';

const TabsContainer = styled.div`
  padding: 0 24px 0 ${BLOCK_PADDING}px;
  max-width: 100%;
  margin-bottom: 16px;

  @media ${DEVICE.mobileWide} {
    padding: 0 16px 0 ${BLOCK_PADDING_MOBILE}px;
    margin-bottom: 10px;
    width: 100%;
  }
`;

type ComponentNames = {
  cardComponentName:
    | HomeLiveBlockTitleCardLog['event']['target']
    | VideoGenreTopLiveBlockTitleCardLog['event']['target']
    | VideoSubjectTopLiveBlockTitleCardLog['event']['target'];
  tabComponentName:
    | HomeLiveBlockGroupTabLog['event']['target']
    | VideoGenreTopLiveBlockGroupTabLog['event']['target'];
  moreComponentName:
    | HomeLiveBlockMoreLog['event']['target']
    | VideoGenreTopLiveBlockMoreLog['event']['target']
    | VideoSubjectTopLiveBlockMoreLog['event']['target'];
  moreCardComponentName:
    | HomeLiveBlockMoreCardLog['event']['target']
    | VideoGenreTopLiveBlockMoreCardLog['event']['target']
    | VideoSubjectTopLiveBlockMoreCardLog['event']['target'];
  pageNextComponentName:
    | HomeLiveBlockPageNextLog['event']['target']
    | VideoGenreTopLiveBlockPageNextLog['event']['target']
    | VideoSubjectTopLiveBlockPageNextLog['event']['target'];
  pagePrevComponentName:
    | HomeLiveBlockPagePrevLog['event']['target']
    | VideoGenreTopLiveBlockPagePrevLog['event']['target']
    | VideoSubjectTopLiveBlockPagePrevLog['event']['target'];
};

type LiveBlockProps = {
  genreCode?: string;
  liveBlock: LiveBlockFragment;
  rowIndex: number;
  context: 'home' | 'videoGenreTop' | 'videoSubjectTop';
  isLazy: boolean;
};

const LiveBlock: React.FC<LiveBlockProps> = ({
  genreCode,
  liveBlock,
  rowIndex,
  context,
  isLazy,
}) => {
  const intl = useIntl();
  const { genreQuery } = useGenreQuery();
  const { labelQuery } = useLabelQuery();
  const { leagueCode } = useLeagueQuery();
  const [activeGroup, setActiveGroup] = useState(0);
  const { code, name, structureType, list } = liveBlock;

  const cardComponentName: ComponentNames['cardComponentName'] = `${context}-liveBlock-titleCard`;
  const moreComponentName: ComponentNames['moreComponentName'] = `${context}-liveBlock-more`;
  const moreCardComponentName: ComponentNames['moreCardComponentName'] = `${context}-liveBlock-moreCard`;
  const pageNextComponentName: ComponentNames['pageNextComponentName'] = `${context}-liveBlock-pageNext`;
  const pagePrevComponentName: ComponentNames['pagePrevComponentName'] = `${context}-liveBlock-pagePrev`;
  let tabComponentName: ComponentNames['tabComponentName'] | undefined;
  if (context === 'home' || context === 'videoGenreTop') {
    tabComponentName = `${context}-liveBlock-groupTab`;
  }

  const detailHref = {
    pathname:
      structureType === 'FLAT'
        ? `/live/browse/block/${code}`
        : `/live/browse/group/${list[activeGroup].code}`,
    query: genreQuery
      ? { genre: genreQuery }
      : labelQuery
      ? { label: labelQuery }
      : leagueCode
      ? { [UrlQueryParams.LEAGUE_CODE]: leagueCode }
      : {},
  };
  const keyPrefix = `live-slider-${rowIndex}`;

  const sendGroupTabClickLog = (index: number) => {
    if (tabComponentName) {
      getKafkaClient().trackUserClickDimension2<
        HomeLiveBlockGroupTabLog | VideoGenreTopLiveBlockGroupTabLog
      >(tabComponentName, {
        row_index: getKafkaLogRowIndex(COUNT_BLOCK_CLASS_NAME, keyPrefix),
        column_index: index,
        live_group_code: list[index].code || '',
        tracking_block_code: liveBlock.trackingCode,
        leanback_genre_code: genreCode,
      });
    }
  };

  const sendPrevNextClickLog =
    (
      componentName: typeof pageNextComponentName | typeof pagePrevComponentName
    ) =>
    () => {
      getKafkaClient().trackUserClickDimension1<
        | HomeLiveBlockPagePrevLog
        | HomeLiveBlockPageNextLog
        | VideoGenreTopLiveBlockPagePrevLog
        | VideoGenreTopLiveBlockPageNextLog
        | VideoSubjectTopLiveBlockPagePrevLog
        | VideoSubjectTopLiveBlockPageNextLog
      >(componentName, {
        index: getKafkaLogRowIndex(COUNT_BLOCK_CLASS_NAME, keyPrefix),
        tracking_block_code: liveBlock.trackingCode,
        leanback_genre_code: genreCode,
        subject_genre_code: genreCode,
      });
    };

  const sendMoreClickLog =
    (componentName: typeof moreComponentName | typeof moreCardComponentName) =>
    () => {
      getKafkaClient().trackUserClickDimension1<
        | HomeLiveBlockMoreLog
        | HomeLiveBlockMoreCardLog
        | VideoGenreTopLiveBlockMoreLog
        | VideoGenreTopLiveBlockMoreCardLog
        | VideoSubjectTopLiveBlockMoreLog
        | VideoSubjectTopLiveBlockMoreCardLog
      >(componentName, {
        index: getKafkaLogRowIndex(COUNT_BLOCK_CLASS_NAME, keyPrefix),
        tracking_block_code: liveBlock.trackingCode,
        leanback_genre_code: genreCode,
        subject_genre_code: genreCode,
      });
    };

  return (
    <BlockContainer key={`live-block-${code}`} data-ucn="liveBlock">
      <Link href={detailHref} passHref legacyBehavior>
        <BlockTitle.StyledLink
          onClick={sendMoreClickLog(moreComponentName)}
          data-ucn="blockHeading-more-link"
        >
          {code === 'SPOTV' ? (
            <BlockTitle.LinkLogo>
              <SPOTVLogo />
            </BlockTitle.LinkLogo>
          ) : (
            <BlockTitle.LinkText>{name}</BlockTitle.LinkText>
          )}
          <BlockTitle.RightContainer>
            <BlockTitle.LinkButton />
          </BlockTitle.RightContainer>
        </BlockTitle.StyledLink>
      </Link>
      {structureType === 'GROUP' && (
        <TabsContainer>
          <StatelessTabs
            tabs={list?.map((group) => ({
              text: group.name ?? '-', // typeエラーに対処するためのfallbackテキスト
              hasOnAirLive: group.liveList.some(
                (live) => getLiveOnAirStatus(live) === 'LIVE_NOW'
              ),
            }))}
            activeIndex={activeGroup}
            setActiveIndex={setActiveGroup}
            onTabItemClick={sendGroupTabClickLog}
            data-ucn="blockTab-btn"
          />
        </TabsContainer>
      )}
      <Slider
        key={`${keyPrefix}_${activeGroup}`}
        type="live"
        keyPrefix={keyPrefix}
        onClickLeftButton={sendPrevNextClickLog(pagePrevComponentName)}
        onClickRightButton={sendPrevNextClickLog(pageNextComponentName)}
        items={({ activeRange }) => {
          const { liveList, total } = list[activeGroup];
          const items = liveList.map((live, index) => {
            return (
              <FocusStyledTitleLink
                type="live"
                data-ucn="liveBlock-item-link"
                titleCode={live.id}
                key={`key-${live.id}-${index}`}
                tabIndex={isActive(index, activeRange) ? undefined : -1}
                role="button"
                onClick={() => {
                  getKafkaClient().trackUserClickDimension2<
                    | HomeLiveBlockTitleCardLog
                    | VideoGenreTopLiveBlockTitleCardLog
                    | VideoSubjectTopLiveBlockTitleCardLog
                  >(cardComponentName, {
                    row_index: getKafkaLogRowIndex(
                      COUNT_BLOCK_CLASS_NAME,
                      keyPrefix
                    ),
                    column_index: index,
                    live_code: live.id,
                    tracking_block_code: liveBlock.trackingCode,
                    leanback_genre_code: genreCode,
                    subject_genre_code: genreCode,
                  });
                }}
              >
                <LiveTitleCard
                  name={live.name}
                  thumbnailUrl={`//${selectNotice(live)?.thumbnail?.standard}`}
                  isPoint={isPoint(live)}
                  deliveryStartDateTime={live.deliveryStartDateTime}
                  minimumPrice={
                    live.tickets.some((ticket) => ticket.price)
                      ? Math.min(...live.tickets.map((ticket) => ticket.price))
                      : undefined
                  }
                  hasMultiplePrice={live.tickets.length > 1}
                  hasCatchup={hasCatchup(live)}
                  isOnlyOn={live.isOnlyOn}
                  liveOnAirStatus={getLiveOnAirStatus(live)}
                  componentName={cardComponentName}
                  isLazy={isLazy}
                />
              </FocusStyledTitleLink>
            );
          });

          if (total > LIVES_PER_BLOCK) {
            const titleNumText = intl.formatMessage(
              total < 50
                ? globalMessages.countNumber
                : globalMessages.countNumberOverFifty,
              { count: intl.formatNumber(total) }
            );
            items.push(
              <Link href={detailHref} passHref>
                <ReadMoreCard
                  titleNumText={titleNumText}
                  type="live"
                  onClick={sendMoreClickLog(moreCardComponentName)}
                />
              </Link>
            );
          }
          return items;
        }}
      />
    </BlockContainer>
  );
};

export default LiveBlock;
