import { ApolloQueryResult } from '@apollo/client';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { cosmo_genreQuery } from '../../../__generated__/globalTypes';
import { isPoint } from '../../../shared/components/PointPriceBadge';
import Slider, {
  COUNT_BLOCK_CLASS_NAME,
} from '../../../shared/components/Slider';
import ReadMoreCard from '../../../shared/components/TitleCard/ReadMore';
import { ExpandableVideoCard } from '../../../shared/components/TitleCard/Video';
import { FocusStyledTitleLink } from '../../../shared/components/TitleLink';
import { UrlQueryParams } from '../../../shared/constants';
import { globalMessages } from '../../../shared/constants/messages';
import useGenreQuery from '../../../shared/hooks/useGenreQuery';
import useLabelQuery from '../../../shared/hooks/useLabelQuery';
import useVerticalScroll from '../../../shared/hooks/useVerticalScroll';
import { DEVICE } from '../../../shared/styles';
import { handleOnlyOnEnter, mapNumberToWeekdayKey } from '../../../utils';
import type { HomeAltBlockTitleCardLog } from '../../Log/__types__/home-altBlock-titleCard';
import type { VideoGenreTopAltBlockTitleCardLog } from '../../Log/__types__/videoGenreTop-altBlock-titleCard';
import { getKafkaClient } from '../../Log/kafkaClient';
import BlockHeading from '../BlockHeading';
import BlockTitle from '../BlockTitle';
import {
  BLOCKS_MARGIN,
  BLOCKS_MARGIN_MOBILE,
  PAGE_SIZE,
  TITLES_PER_BLOCK,
} from '../constants';
import SliderPlaceholder from '../SliderPlaceholder';
import { getKafkaLogRowIndex, isActive } from '../utils';

interface RecommendedBlocksContainerProps {
  isDisabled?: boolean;
}

const RecommendedBlocksContainer = styled.div.attrs<RecommendedBlocksContainerProps>(
  (props) => ({
    style: {
      pointerEvents: props.isDisabled ? 'none' : 'all',
    },
  })
)<RecommendedBlocksContainerProps>`
  position: relative;
  z-index: 90;
`;

export const BlockContainer = styled.div`
  margin-bottom: ${BLOCKS_MARGIN}px;

  @media ${DEVICE.mobileWide} {
    margin-bottom: ${BLOCKS_MARGIN_MOBILE}px;
  }
`;

const MAX_PAGES = 10;

interface RecommendedBlocksProps {
  data: cosmo_genreQuery;
  loading: boolean;
  fetchPageData: (page: number) => Promise<ApolloQueryResult<cosmo_genreQuery>>;
  didFetchMore?: boolean;
  leanbackGenreCode: string;
}

const RecommendedBlocks: React.FC<RecommendedBlocksProps> = ({
  data,
  loading,
  fetchPageData,
  didFetchMore,
  leanbackGenreCode,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const { genreQuery } = useGenreQuery();
  const { labelQuery } = useLabelQuery();
  const currentPage = data.webfront_recommendBlocks?.pageInfo.page ?? 1;
  const nextPage = currentPage + 1;
  const hasNextPage =
    nextPage <=
    Math.min(data.webfront_recommendBlocks?.pageInfo.pages ?? 0, MAX_PAGES);
  const scrollCallback = () => {
    if (nextPage <= MAX_PAGES) {
      fetchPageData(nextPage);
    }
  };
  const isScrolling = useVerticalScroll(
    scrollCallback,
    hasNextPage && !loading && nextPage <= MAX_PAGES
  );

  let placeholderCount = 1;
  if (data.webfront_recommendBlocks) {
    const { pageInfo } = data.webfront_recommendBlocks;
    placeholderCount = hasNextPage
      ? pageInfo.pageSize
      : Math.ceil(pageInfo.results / pageInfo.pageSize);
  }

  return (
    <RecommendedBlocksContainer isDisabled={isScrolling}>
      {data.webfront_recommendBlocks?.recommendBlocks
        .filter((block) => block.titles.length > 0) // バックエンドの様々な要因で、タイトルリストが空のブロックが返ってくるケースがあるため、フィルターする
        .map((block, rowIndex) => {
          const keyPrefix = `genre-slider-${rowIndex}`;
          const titleNum = block.titleResults;
          const titleNumText = titleNum
            ? intl.formatMessage(
                titleNum < 50
                  ? globalMessages.countNumber
                  : globalMessages.countNumberOverFifty,
                { count: intl.formatNumber(titleNum) }
              )
            : '';
          const featureDetailHref = {
            pathname: `/browse/feature/${block.id}`,
            query: genreQuery
              ? { genre: genreQuery }
              : labelQuery
              ? { label: labelQuery }
              : {},
          };
          return (
            <BlockContainer
              key={`ft-${rowIndex}-${block.id}`}
              data-ucn="recommendBlock"
            >
              <BlockHeading
                hasNoLead={!block.leadText}
                href={featureDetailHref}
                title={block.featureName ?? ''}
                numText={titleNumText}
              />
              {block.leadText && (
                <BlockTitle.Lead>{block.leadText}</BlockTitle.Lead>
              )}
              <Slider
                type="video"
                keyPrefix={keyPrefix}
                items={({ activeRange }) => {
                  const titles = block.titles.map((title, columnIndex) => {
                    const weekdayKey = mapNumberToWeekdayKey(
                      title.updateOfWeek
                    );
                    const weekdayText = weekdayKey
                      ? intl.formatMessage(globalMessages[weekdayKey])
                      : '';
                    const updateWeekdayInfo =
                      weekdayText &&
                      intl.formatMessage(globalMessages.updateText, {
                        updateText: weekdayText,
                      });
                    const trackFeatureTitleClick = () => {
                      getKafkaClient().trackUserClickDimension2<
                        | HomeAltBlockTitleCardLog
                        | VideoGenreTopAltBlockTitleCardLog
                      >(
                        router.pathname === '/'
                          ? 'home-altBlock-titleCard'
                          : 'videoGenreTop-altBlock-titleCard',
                        {
                          row_index: getKafkaLogRowIndex(
                            COUNT_BLOCK_CLASS_NAME,
                            keyPrefix
                          ),
                          column_index: columnIndex,
                          feature_code: block.id,
                          sakuhin_code: title.id,
                          leanback_genre_code: leanbackGenreCode,
                          video_list: block.titles
                            .slice(0, columnIndex)
                            .map((title) => ({
                              sakuhin_code: title.id,
                              has_new_badge: !!title.isNew,
                              has_payment_badge: isPoint(title),
                            })),
                        }
                      );
                    };

                    const catchphrase =
                      block.titleComments.find(
                        (comment) => comment.titleCode === title.id
                      )?.comment ||
                      title.catchphrase ||
                      '';

                    return (
                      <FocusStyledTitleLink
                        type="video"
                        titleCode={title.id}
                        key={`key-${title.id}-${columnIndex}`}
                        tabIndex={
                          isActive(columnIndex, activeRange) ? undefined : -1
                        }
                        role="button"
                        onKeyDown={(e) => {
                          handleOnlyOnEnter(e, trackFeatureTitleClick);
                        }}
                        onClick={trackFeatureTitleClick}
                        query={{
                          [UrlQueryParams.FEATURE_CODE]: block.id,
                        }}
                        data-ucn="recommendBlock-item-link"
                      >
                        <ExpandableVideoCard
                          isSimplified={rowIndex >= PAGE_SIZE} // Simplify the DOM except first PAGE_SIZE rows.
                          titleName={title.titleName}
                          year={title.productionYear}
                          rate={title.rate}
                          thumbnailUrl={`//${title.thumbnail?.standard}`}
                          catchphrase={catchphrase}
                          updateInfo={
                            updateWeekdayInfo && title.lastEpisode
                              ? `${updateWeekdayInfo} ${title.lastEpisode}`
                              : updateWeekdayInfo || title.lastEpisode || ''
                          }
                          nfreeBadge={title.nfreeBadge}
                          hasSub={title.hasSubtitle}
                          hasDub={title.hasDub}
                          isNew={title.isNew}
                          isPoint={isPoint(title)}
                          isLazy={!!didFetchMore}
                          isOnlyOn={title.exclusive.isOnlyOn}
                          price={title.minimumPrice || undefined}
                          hasMultiprice={!!title.hasMultiprice}
                        />
                      </FocusStyledTitleLink>
                    );
                  });

                  if (
                    typeof titleNum === 'number' &&
                    titleNum > TITLES_PER_BLOCK
                  ) {
                    titles.push(
                      <Link href={featureDetailHref}>
                        <ReadMoreCard
                          titleNumText={titleNumText}
                          type="video"
                        />
                      </Link>
                    );
                  }

                  return titles;
                }}
              />
            </BlockContainer>
          );
        })}
      {loading &&
        [...Array(placeholderCount)].map((_item, i) => (
          <SliderPlaceholder key={`placeholder-feature-${i}`} />
        ))}
    </RecommendedBlocksContainer>
  );
};

export default RecommendedBlocks;
