import { gql } from '@apollo/client';

export const GET_LEAGUE = gql`
  query cosmo_league($leagueCode: ID!) {
    leagueDetail(leagueCode: $leagueCode) {
      league {
        name
        code
        tagCode
        thumbnail {
          secondary
        }
      }
    }
  }
`;
