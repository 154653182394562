import { useRouter } from 'next/router';
import { cosmo_all_labelQuery } from '../../__generated__/globalTypes';
import { GET_ALL_LABEL } from '../../domain/freeword/gql';
import { getLabelCodeByUrlKey } from '../../utils';
import useClientQuery from './useClientQuery';

/**
 * Gets label information
 */
const useLabelQuery = (): {
  labelName?: string;
  labelQuery: string;
  labelGenreId?: string;
  labelLoading: boolean;
} => {
  const router = useRouter();
  const query = router.query.label;
  const labelQuery = Array.isArray(query) ? query[0] : query || '';
  const labelCode = getLabelCodeByUrlKey(labelQuery);

  const { loading: labelLoading, data: labelData } =
    useClientQuery<cosmo_all_labelQuery>(GET_ALL_LABEL, {
      skip: !labelCode,
    });

  const labels = labelData?.labelBlock.labelList;
  const label = labels?.find((item) => item.code === labelCode);

  return {
    labelGenreId: label?.genreCode,
    labelQuery,
    labelName: label?.name,
    labelLoading,
  };
};

export default useLabelQuery;
